<template>
    <div>
        <AssetList :assets="assets" />
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import AssetList from '../AssetList'

export default {
    name: 'AssetListView',
    components: {
        AssetList,
    },
    props: {
        type: {
            type: String,
            required: true,
        },
    },
    computed: {
        ...mapState('tracker', ['trackers']),
        ...mapGetters('tracker', [
            'assetsInLocation',
            'lostAssets',
            'lowBatteryAssets',
        ]),
        assets() {
            switch (this.type) {
                case 'ASSETS_ALL':
                    return this.trackers
                case 'ASSETS_LOST':
                    return this.lostAssets
                case 'ASSETS_LOW_BATTERY':
                    return this.lowBatteryAssets
                case 'LOCATION':
                    return this.assetsInLocation(this.$route.params.location)
                default:
                    console.error('Unkown asset type')
                    return []
            }
        },
    },
}
</script>
